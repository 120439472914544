import { useEffect, useState } from 'react';
import { Card, Form, Row, Col, InputGroup, Button, Badge } from 'react-bootstrap';
import dataProvider from '../../dataProvider';
import Spinner from '../Common/SpinnerFede';
import Moment from 'moment';

const StudentEdit = (props) => {

    const [validated, setValidated] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const [isLoading,setIsLoading] = useState(false);
    const [nationalities, setNationality] = useState(null);
    const [schools, setSchool] = useState(null);
    const [isRemote, setIsRemote] = useState(false);
    const [item,setItem] = useState({ title: ""});
    const {title} = item;
    const [birthday, setBirthday] = useState(null);

    const onSwitchRemote = () => {
        setIsRemote(value => !value);
    };

    const handleCiviliteChange = (event) => {
        event.persist();
        setItem(prevState => ({
            ...prevState,
            title: event.target.value
        }));
    };

    useEffect(() => {
        if(!data){
            async function getData() {
                try {
                    setIsLoading(true)
                    const student = await dataProvider("GET", "/students/" + props.id)
                    setData(student)
                    const nationalities = await dataProvider("GET", "/nationalities")
                    setNationality(nationalities)
                    const schools = await dataProvider("GET", "/schools")
                    setSchool(schools)
                    setIsLoading(false)
                } catch(e) {
                    setError(e)
                }
                
            }
            getData();
        }
    },  [data])

    useEffect(() => {
        if(data){
            setBirthday(Moment(data.birthday).format('L'));
            setItem({"title" : data.title});
            console.log(item)
        }
    }, [data])

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        setValidated(true);
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
          alert("Erreur: Vous devez renseigner les champs obligatoires !");
        } else {
        
            async function patchData() {
                event.preventDefault();
                event.stopPropagation();
                const body = {}
                for(var i=0; i<(event.target.length - 1); i++){
                    if (event.target[i].name=="remote"){
                        body[event.target[i].name] = isRemote;
                        continue;
                    }
                    if (event.target[i].name=="nationalityId"){
                        var val = parseInt(event.target[i].value);
                        body[event.target[i].name] = val;
                        continue;
                    }
                    if (event.target[i].name=="schoolId"){
                        var val = parseInt(event.target[i].value);
                        body[event.target[i].name] = val;
                        continue;
                    }
                    if(event.target[i].name=="title"){
                        const val = title;
                        body[event.target[i].name] = val;
                        continue;
                    }
                    body[event.target[i].name] = event.target[i].value;
                }
                try {
                    const response = await dataProvider("PATCH", "/students/" + props.id, body)
                    alert('Modifications sauvegardés')
                    return response;
                } catch(error) {
                    console.log(error)
                    alert(error);
                }
                
            }
        
            patchData();
        }
    };

    return (

        <Row>
            <Card>
                <Row>
                    <Col className="b-orange nomargin"></Col>
                    <Col className="b-vert nomargin"></Col>
                    <Col className="b-violet nomargin"></Col>
                    <Col className="b-bleu nomargin"></Col>
                </Row>
                <Row>
                    <Col lg={5} className="bggrey"></Col>
                    <Col className="bggrey"><h2 className="fedetitle">Candidat</h2></Col>
                </Row>
                <Card.Body>
                    {!isLoading && data ? (
                        <div>
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                <Row className="mb-3 mt-3">
                                    <Form.Group as={Col} lg="2" controlId="title">
                                        <Form.Label>Civilité</Form.Label>
                                        <InputGroup>
                                            <Form.Check
                                                inline
                                                label="Mme"
                                                name="title"
                                                type="radio"
                                                id={`inline-radio-1`}
                                                onChange={handleCiviliteChange}
                                                checked={title === "Mme"}
                                                value="Mme"
                                            />
                                            <Form.Check
                                                inline
                                                label="Mlle"
                                                name="title"
                                                type="radio"
                                                id={`inline-radio-2`}
                                                onChange={handleCiviliteChange}
                                                checked={title === "Mlle"}
                                                value="Mlle"
                                            />
                                            <Form.Check
                                                inline
                                                label="M"
                                                name="title"
                                                type="radio"
                                                id={`inline-radio-3`}
                                                onChange={handleCiviliteChange}
                                                checked={title === "M"}
                                                value="M"
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col} lg="4" controlId="validationCustom02">
                                        <Form.Label>Nom</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Nom"
                                            defaultValue={data.lastname}
                                            name="lastname"
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} lg="4" controlId="validationCustom02">
                                        <Form.Label>Prénom</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Prénom"
                                            defaultValue={data.firstname}
                                            name="firstname"
                                        />
                                    </Form.Group>
                                </Row>

                                <Row className="mb-3">
                                    <Form.Group as={Col} lg="4" controlId="validationCustom02">
                                        <Form.Label>Date de naissance</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Date de naissance"
                                            defaultValue={birthday}
                                            name="birthday"
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} lg="4" controlId="validationCustom02">
                                        <Form.Label>Ville de naissance</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Ville de naissance"
                                            defaultValue={data.birthplace}
                                            name="birthplace"
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} lg="4" controlId="validationCustomUsername">
                                        <Form.Label>Email</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                                            <Form.Control
                                            type="email"
                                            placeholder="Email"
                                            aria-describedby="inputGroupPrepend"
                                            defaultValue={data.email}
                                            name="email"
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                </Row>

                                <Row className="mb-3">
                                    <Form.Group as={Col} lg="4" controlId="validationCustom02">
                                        <Form.Label>Téléphone</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Téléphone"
                                            defaultValue={data.phone}
                                            name="phone"
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} lg="4" controlId="validationCustom02">
                                        <Form.Label>Portable</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Portable"
                                            defaultValue={data.mobile}
                                            name="mobile"
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} lg="4">
                                        <Form.Label>Email Fedecampus</Form.Label>
                                        <InputGroup hasValidation>
                                            <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                                            <Form.Control
                                            type="email"
                                            placeholder="Email Fedecampus"
                                            aria-describedby="inputGroupPrepend"
                                            defaultValue={data.emailFedecampus}
                                            name="emailFedecampus"
                                            disabled
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                </Row>

                                <Row className="mb-3">
                                    <Form.Group as={Col} lg="6">
                                        <Form.Label>Nationalité</Form.Label>
                                        <Form.Select aria-label="nationalité" defaultValue={data.nationalityId} name="nationalityId">
                                            <option></option>
                                            {
                                                nationalities && nationalities.map(item => (
                                                    <option value={item.id} >{item.value}</option>
                                                ))
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group as={Col} lg="6">
                                        <Form.Label>Ecole</Form.Label>
                                        <Form.Select aria-label="école" defaultValue={data.schoolId} name="schoolId">
                                            <option></option>
                                            {
                                                schools && schools.map(item => (
                                                    <option value={item.id}>{item.name}</option>
                                                ))
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                </Row>
                                
                                <Row className="mb-3">
                                    <Form.Group as={Col} lg="4" controlId="validationCustom02">
                                        <Form.Label>Département du centre d'examen</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Département"
                                            defaultValue={data.dpCenterChoice}
                                            name="dpCenterChoice"
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} lg={{offset:1}}>
                                        <Form.Label></Form.Label>
                                        <Form.Group as={Col} md="6">
                                            <Form.Check
                                                type="switch"
                                                id="remote"
                                                label="A distance"
                                                name="remote"
                                                onChange={onSwitchRemote}
                                                checked={isRemote}
                                            />
                                        </Form.Group>
                                    </Form.Group>
                                </Row>
                                
                                <hr/>
                                
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId="validationCustom05">
                                        <Form.Label>Adresse</Form.Label>
                                        <Form.Control type="text" placeholder="Adresse" defaultValue={data.address} name="address" />
                                        <Form.Control.Feedback type="invalid">
                                            Entrer une adresse valide
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId="validationCustom05">
                                        <Form.Label>Complément d'adresse</Form.Label>
                                        <Form.Control type="text" placeholder="Adresse complémentaire" defaultValue={data.extra_address} name="extra_address"/>
                                        <Form.Control.Feedback type="invalid">
                                            Entrer une adresse valide
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>

                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId="validationCustom05">
                                        <Form.Label>Complément d'adresse 2</Form.Label>
                                        <Form.Control type="text" placeholder="Adresse complémentaire" defaultValue={data.extra_address_3} name="extra_address_3"/>
                                        <Form.Control.Feedback type="invalid">
                                            Entrer une adresse valide
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>

                                <Row className="mb-3">
                                    <Form.Group as={Col} lg="2" controlId="validationCustom05">
                                        <Form.Label>Code Postal</Form.Label>
                                        <Form.Control type="text" placeholder="Code postal" maxLength="14" defaultValue={data.postalCode} name="postalCode"/>
                                        <Form.Control.Feedback type="invalid">
                                            Entrer un code postal valide
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} lg="5" controlId="validationCustom03">
                                        <Form.Label>Ville</Form.Label>
                                        <Form.Control type="text" placeholder="Ville" defaultValue={data.city} name="city"/>
                                        <Form.Control.Feedback type="invalid">
                                            Entrer une ville valide
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} lg="5" controlId="validationCustom04">
                                        <Form.Label>Pays</Form.Label>
                                        <Form.Control type="text" placeholder="Pays" defaultValue={data.country} name="country"/>
                                        <Form.Control.Feedback type="invalid">
                                            Entrer un pays valide
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>

                                <Row>
                                    <Col lg={{ offset: 5 }}>
                                        <Button type="submit" className="center">Valider</Button>
                                    </Col>
                                </Row>
                            </Form>
                            
                        </div>
                    ) : (<Spinner />) }
                </Card.Body>
            </Card>
        </Row>
    );
  }

export default StudentEdit;