import React from 'react';
import './App.css';
import Sidebar from './Components/Common/Sidebar';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import StudentShow from './Components/Students/StudentShow';
import SchoolShow from './Components/Schools/SchoolShow';
import NationalityShow from './Components/Nationalities/NationalityShow';
import SessionShow from './Components/Sessions/SessionShow';
import NotFound from './Components/NotFound';
// MSAL imports
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { BrowserUtils, InteractionType } from "@azure/msal-browser";

function App({ pca }) {

  const authProvider = {
    // authentication
    login: params => Promise.resolve(),
    checkError: error => Promise.resolve(),
    checkAuth: params => Promise.resolve(),
    logout: () =>  pca.logoutRedirect({
        account: pca.getActiveAccount(),
        onRedirectNavigate: () => !BrowserUtils.isInIframe()
    }),
    getIdentity: () => Promise.resolve(),
    // authorization  
    getPermissions: ()  => Promise.resolve(),
  };

  return (
    <MsalProvider instance={pca}>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      <div className="container-fluid">
        <div className="row">
          <Sidebar />
          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <Router>
              <Switch>
                <Route path="/students/:id" children={<StudentShow/>} />
                <Route path="/schools/:id" children={<SchoolShow/>} />
                <Route path="/nationalities/:id" children={<NationalityShow/>} />
                <Route path="/sessions/:id" children={<SessionShow/>} />
                <Route path="*" component={NotFound}/>
              </Switch>
            </Router>
          </main>
        </div>
      </div>

      </MsalAuthenticationTemplate>
      </MsalProvider>
  );
}

export default App;
