import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig, apiCdeConfig } from "./authConfig";

const dataProvider = async (http, url, body) => {

  const msalInstance = new PublicClientApplication(msalConfig);
  const accounts = msalInstance.getAllAccounts();
  const request = {
    scopes: [apiCdeConfig.scopes],
    account: accounts[0],
  };

  const authResult = await msalInstance.acquireTokenSilent(request);
  const token = authResult.accessToken;

  const requestOptions = {
    method: http,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(body)
  }
  console.log(requestOptions);
  const response = await fetch( process.env.REACT_APP_API_URL + url, requestOptions);
  console.log(response.status);
  console.log(response.body);
  if (response.status >= 400 && response.status < 600) {
    if(response.message){
      throw new Error(response.message);
    } else {
      throw new Error(response.statusText);
    }

  }
  console.log(response.message);
  const json = await response.json();
  return json;

}

export default dataProvider;
