import { Card, Container, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import SchoolEdit from './SchoolEdit';

const SchoolShow = () => {

    let {id} = useParams();

    return (
        
        <Container fluid>
            <Row>
                <Col>
                    <Card>
                        <SchoolEdit id={id}/>
                    </Card>
                </Col>
            </Row>
        </Container>
    );

}

export default SchoolShow;