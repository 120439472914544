import { Card, Container, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import StudentEdit from './StudentEdit';

const StudentShow = () => {

    let {id} = useParams();

    return (
        
        <Container fluid>
            <Row>
                <Col>
                    <StudentEdit id={id}/>
                </Col>
            </Row>
        </Container>
    );

}

export default StudentShow;