import React, {useState} from 'react';
import fede_img from '../img/FEDE-OING.png';
import { Button, Collapse, Form, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect
} from "react-router-dom";
import StudentShow from './../Students/StudentShow'; 
import SchoolShow from './../Schools/SchoolShow';

const Sidebar = () => {

    const [open, setOpen] = useState(false);
    const [research, setResearch] = useState(null);
    const [redirect, setRedirect] = useState(false);

    const handleSubmit = (event) => {
        setRedirect(true);
        setResearch(event.target[0].value);
    };

    return (
        <div id="sidebar" className="col-md-3 col-lg-2 p-0">
            <Router>
                {redirect &&
                    <Redirect to={"/students/" + research} />
                }
                <div className="col-lg-12 d-flex flex-column flex-shrink-0 p-3 bg-light">
                    <a href="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">
                        <span className="fs-4"><img src={ fede_img } alt="logo FEDE" width="100px"></img></span>
                    </a>
                    <hr />
                    <ul className="list-unstyled ps-0">
                        <li className="mb-1">
                            <Button 
                                onClick={() => setOpen(!open)}
                                aria-controls="home-collapse"
                                aria-expanded={open}
                                className="btn btn-toggle align-items-center rounded" 
                                variant="link"
                            >
                                Candidat
                            
                            </Button>
                            <Collapse in={open}>
                            <div id="home-collapse">
                                <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                                    <Form className="d-flex" onSubmit={handleSubmit}>
                                        <FormControl
                                            id="research"
                                            type="search"
                                            placeholder="Numéro étudiant"
                                            className="mr-2"
                                            aria-label="Search"
                                            size="sm"
                                            onChange={(e) => e.target.value}
                                        />
                                        <Button type="submit" variant="outline-success" size="sm"><FontAwesomeIcon icon={faSearch} /></Button>
                                    </Form>
                                    {/*
                                    <Link to="/Students/StudentShow" className="link-dark rounded">Candidats</Link>
                                    <li><a href="#" className="link-dark rounded">Updates</a></li>
                                    <li><a href="#" className="link-dark rounded">Reports</a></li>
                                    */}
                                </ul>
                            </div>
                            </Collapse>
                        </li>
                        {/*}
                        <li className="mb-1">
                            <button className="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#dashboard-collapse" aria-expanded="false">Diplôme</button>
                            <div className="collapse" id="dashboard-collapse">
                                <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                                    <li><a href="#" className="link-dark rounded">Overview</a></li>
                                    <li><a href="#" className="link-dark rounded">Weekly</a></li>
                                    <li><a href="#" className="link-dark rounded">Monthly</a></li>
                                    <li><a href="#" className="link-dark rounded">Annually</a></li>
                                </ul>
                            </div>
                        </li>
                        <li className="mb-1">
                            <button className="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#orders-collapse" aria-expanded="false">Ecoles</button>
                            <div className="collapse" id="orders-collapse">
                                <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                                    <li><a href="#" className="link-dark rounded">New</a></li>
                                    <li><a href="#" className="link-dark rounded">Processed</a></li>
                                    <li><a href="#" className="link-dark rounded">Shipped</a></li>
                                    <li><a href="#" className="link-dark rounded">Returned</a></li>
                                </ul>
                            </div>
                        </li>
                        <li className="border-top my-3"></li>
                        <li className="mb-1">
                            <button className="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#account-collapse" aria-expanded="false">Administration</button>
                            <div className="collapse" id="account-collapse">
                                <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                                    <li><a href="#" className="link-dark rounded">New...</a></li>
                                    <li><a href="#" className="link-dark rounded">Profile</a></li>
                                    <li><a href="#" className="link-dark rounded">Settings</a></li>
                                    <li><a href="#" className="link-dark rounded">Sign out</a></li>
                                </ul>
                            </div>
                        </li>
                        */}
                    </ul>
                    <hr />

                    {/*
                    <div className="dropdown">
                        <a href="#" className="d-flex align-items-center text-white text-decoration-none dropdown-toggle show" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="true">
                            <img src="https://github.com/mdo.png" alt="" width="32" height="32" className="rounded-circle me-2"></img>
                            <strong>mdo</strong>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-dark text-small shadow show dropdown-open" aria-labelledby="dropdownUser1" data-popper-placement="top-start">
                            <li><a className="dropdown-item" href="#">New project...</a></li>
                            <li><a className="dropdown-item" href="#">Settings</a></li>
                            <li><a className="dropdown-item" href="#">Profile</a></li>
                            <li><hr className="dropdown-divider"/></li>
                            <li><a className="dropdown-item" href="#">Sign out</a></li>
                        </ul>
                    </div>
                    */}
                </div>
            </Router>
        </div>
    );
}

export default Sidebar;