import { useEffect, useState } from 'react';
import { Card, Form, Row, Col, InputGroup, Button } from 'react-bootstrap';
import dataProvider from '../../dataProvider';
import Spinner from '../Common/SpinnerFede';

const SchoolEdit = (props) => {
    
    const [validated, setValidated] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const [isLoading,setIsLoading] = useState(false);
    
    useEffect(() => {
        if(!data){
            async function getData() {
                try {
                    setIsLoading(true)
                    const school = await dataProvider("GET", "/schools/" + props.id)
                    setData(school)
                    setIsLoading(false)
                } catch(e) {
                    setError(e)
                }
                
            }
            getData();
        }
    },  [data])

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        setValidated(true);
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
          alert("Erreur: Vous devez renseigner les champs obligatoires !");
        } else {
        
            async function patchData() {
                event.preventDefault();
                event.stopPropagation();
                const body = {}
                for(var i=0; i<(event.target.length - 1); i++){
                    body[event.target[i].name] = event.target[i].value;
                }
                try {
                    const response = await dataProvider("PATCH", "/schools/" + props.id, body)
                    alert('Modifications sauvegardés')
                    return response;
                } catch(error) {
                    console.log(error)
                    alert(error);
                }
                
            }
        
            patchData();
        }
    };

    return (

        <Card>
            <Card.Body>
                {!isLoading && data ? (
                    <div>
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="*-" controlId="validationCustom02">
                                    <Form.Label>Nom</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        name="name"
                                        placeholder="Nom"
                                        defaultValue={data.name}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom02">
                                    <Form.Label>Label</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="label"
                                        placeholder="Label"
                                        defaultValue={data.label}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                            </Row>

                            <Row className="mb-3">
                                <Form.Group as={Col} md="6" controlId="validationCustom02">
                                    <Form.Label>Code inscription</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="schoolCode"
                                        placeholder="Code inscription"
                                        defaultValue={data.schoolCode}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom02">
                                    <Form.Label>Code résultats</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="schoolPwd"
                                        placeholder="Code résultats"
                                        defaultValue={data.schoolPwd}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                            </Row>

                            <hr/>

                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="validationCustom05">
                                    <Form.Label>Adresse</Form.Label>
                                    <Form.Control type="text" name="address" placeholder="Adresse" defaultValue={data.address} required/>
                                    <Form.Control.Feedback type="invalid">
                                        Entrer une adresse valide
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>

                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="validationCustom05">
                                    <Form.Label>Complément d'adresse</Form.Label>
                                    <Form.Control type="text" name="extra_address" placeholder="Adresse complémentaire" defaultValue={data.extra_address} />
                                    <Form.Control.Feedback type="invalid">
                                        Entrer une adresse valide
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>

                            <Row className="mb-3">
                                <Form.Group as={Col} md="2" controlId="validationCustom05">
                                    <Form.Label>Code Postal</Form.Label>
                                    <Form.Control type="text" name="postalCode" placeholder="Code postal" maxLength="14" defaultValue={data.postalCode} required />
                                    <Form.Control.Feedback type="invalid">
                                        Entrer un code postal valide
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="5" controlId="validationCustom03">
                                    <Form.Label>Ville</Form.Label>
                                    <Form.Control type="text" name="city" placeholder="Ville" defaultValue={data.city} required />
                                    <Form.Control.Feedback type="invalid">
                                        Entrer une ville valide
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="5" controlId="validationCustom04">
                                    <Form.Label>Pays</Form.Label>
                                    <Form.Control type="text" name="country" placeholder="Pays" defaultValue={data.country} required/>
                                    <Form.Control.Feedback type="invalid">
                                        Entrer un pays valide
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>

                            <Row>
                                <Col md={{ offset: 5 }}>
                                    <Button type="submit" className="center">Submit form</Button>
                                </Col>
                            </Row>
                        </Form>
                        
                    </div>
                ) : (<Spinner />) }
            </Card.Body>
        </Card>
    );

  }

export default SchoolEdit;