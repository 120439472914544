import spinner from '../img/spinner-fede.gif'

const SpinnerFede = () => {
    return (
        <div id="spinner">
            <img src={spinner} width="100" alt="Spinner" /><span className="sr-only">Loading...</span>
        </div>
    )
}

export default SpinnerFede;