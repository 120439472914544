import { useEffect, useState } from 'react';
import { Card, Form, Row, Col, InputGroup, Button } from 'react-bootstrap';
import dataProvider from '../../dataProvider';
import Spinner from '../Common/SpinnerFede';

const SessionEdit = (props) => {
    
    const [validated, setValidated] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [state, setState] = useState(null);
    const [isOpenedTrue, setIsOpenedTrue] = useState(false);
    const [isArchivedTrue, setIsArchivedTrue] = useState(false);
    
    const onSwitchArchived = () => {
        setIsArchivedTrue(!isArchivedTrue);
    };

    const onSwitchOpened = () => {
        setIsOpenedTrue(!isOpenedTrue);
    };

    useEffect(() => {
        if(!data){
            async function getData() {
                try {
                    setIsLoading(true)
                    const session = await dataProvider("GET", "/sessions/" + props.id)
                    setData(session)
                    setIsLoading(false)
                } catch(e) {
                    setError(e)
                }
                
            }
            getData();
        }
    },  [data])

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }

        async function patchData() {
            event.preventDefault();
          event.stopPropagation();
            const body = {};
            //-1 because we are selecting the button too
            for(var i=0; i<(event.target.length - 1); i++){
                if (event.target[i].name=="opened"){
                    val = new Boolean();
                    val = isOpenedTrue=="true" ? true : false;
                    body[event.target[i].name] = val;
                    continue;
                }
                if (event.target[i].name=="archived"){
                    val = new Boolean();
                    val = isArchivedTrue=="true" ? true : false;
                    body[event.target[i].name] = val;
                    continue;
                }
                if (event.target[i].name=="monthNumber" || event.target[i].name=="year"){
                    var  val = parseInt(event.target[i].value)
                } else {
                    val = event.target[i].value
                }
                body[event.target[i].name] = val;
                
            }
            const session = await dataProvider("PATCH", "/sessions/" + props.id, body)
            event.preventDefault();
          event.stopPropagation();
        }
        patchData();
        setValidated(true);
    };

    return (

        <Card>
            <Card.Body>
                <Card.Title>Session</Card.Title>
                {!isLoading && data ? (
                    <div>
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="6">
                                    <Form.Label>Mois</Form.Label>
                                    <Form.Select aria-label="mois" defaultValue={data.month} name="month">
                                        <option></option>
                                        <option>JANVIER</option>
                                        <option>FEVRIER</option>
                                        <option>MARS</option>
                                        <option>AVRIL</option>
                                        <option>MAI</option>
                                        <option>JUIN</option>
                                        <option>JUILLET</option>
                                        <option>AOUT</option>
                                        <option>SEPTEMBRE</option>
                                        <option>OCTOBRE</option>
                                        <option>NOVEMBRE</option>
                                        <option>DECEMBRE</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} md="2" controlId="validationCustom02">
                                    <Form.Label>Mois / Année</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            type="number"
                                            placeholder="Mois"
                                            defaultValue={data.monthNumber}
                                            maxLength="2"
                                            name="monthNumber"
                                        />
                                        <Form.Control
                                            type="number"
                                            placeholder="Année"
                                            defaultValue={data.year}
                                            maxLength="4"
                                            name="year"
                                        />
                                    </InputGroup>
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                            </Row>

                            <Row className="mb-3">
                                <Form.Group as={Col} md="6">
                                    <Form.Check
                                        type="switch"
                                        id="opened"
                                        label="Session ouverte"
                                        name="opened"
                                        onChange={onSwitchOpened}
                                        checked={isOpenedTrue}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="6">
                                    <Form.Check
                                        type="switch"
                                        id="archived"
                                        label="Session archivé"
                                        name="archived"
                                        onChange={onSwitchArchived}
                                        checked={isArchivedTrue}
                                    />
                                </Form.Group>
                            </Row>

                            <Row>
                                <Col md={{ offset: 5 }}>
                                    <Button type="submit" className="center">Valider</Button>
                                </Col>
                            </Row>
                        </Form>
                        
                    </div>
                ) : (<Spinner />) }
            </Card.Body>
        </Card>
    );

  }

export default SessionEdit;