import React from 'react';
import { Card, Row } from 'react-bootstrap';

const NotFound = () =>
  <Row>
    <Card>
      <Row>
        <div>
          <h3>404 page not found</h3>
          <p>We are sorry but the page you are looking for does not exist.</p>
        </div>
      </Row>
    </Card>
  </Row>

export default NotFound;