import { useEffect, useState } from 'react';
import { Card, Form, Row, Col, Button } from 'react-bootstrap';
import dataProvider from '../../dataProvider';
import Spinner from '../Common/SpinnerFede';

const NationalityEdit = (props) => {
    
    const [validated, setValidated] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const [isLoading,setIsLoading] = useState(false);
    
    useEffect(() => {
        if(!data){
            async function getData() {
                try {
                    setIsLoading(true)
                    const nationality = await dataProvider("GET", "/nationalities/" + props.id)
                    setData(nationality)
                    setIsLoading(false)
                } catch(e) {
                    setError(e)
                }
                
            }
            getData();
        }
    },  [data])

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        setValidated(true);
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
          alert("Erreur: Vous devez renseigner les champs obligatoires !");
        } else {
        
            async function patchData() {
                event.preventDefault();
                event.stopPropagation();
                const body = {}
                for(var i=0; i<(event.target.length - 1); i++){
                    body[event.target[i].name] = event.target[i].value;
                }
                try {
                    const response = await dataProvider("PATCH", "/nationalities/" + props.id, body)
                    alert('Modifications sauvegardés')
                    return response;
                } catch(error) {
                    alert(error);
                }
                
            }
        
            patchData();
        }
    };

    return (

        <Card>
            <Card.Body>
                {!isLoading && data ? (
                    <div>
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="6" controlId="validationCustom02">
                                    <Form.Label>Nationalité</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        name="value"
                                        placeholder="Nationalité"
                                        defaultValue={data.value}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom02">
                                    <Form.Label>Label anglais</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="labelEng"
                                        placeholder="Label anglais"
                                        defaultValue={data.labelEng}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                            </Row>

                            <Row>
                                <Col md={{ offset: 5 }}>
                                    <Button type="submit" className="center">Submit form</Button>
                                </Col>
                            </Row>
                        </Form>
                        
                    </div>
                ) : (<Spinner />) }
            </Card.Body>
        </Card>
    );

  }

export default NationalityEdit;